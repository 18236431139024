<div class="modal-pdv-rupture">
  <div class="modal-header">
    <h4 class="modal-title">{{ modalTitle }}</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <div class="pointer float-end mb-2" (click)="export(modalType)">
      <img src="assets/images/icons/external.svg" alt="Exporter">
    </div>
    <ng-template *ngxPermissionsOnly="permissionName">
      <div class="pointer float-end mb-2" (click)="export(modalType)">
        <img src="assets/images/icons/external.svg" alt="Exporter">
      </div>
    </ng-template>
    <div class="custom-table table-responsive table__territoire table__with__jauge">
      <table class="table table-striped" datatable [dtOptions]="pdvDtOptions" [dtTrigger]="pdvDtTrigger"></table>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Fermer</button>
  </div>
  <ngx-ui-loader [loaderId]="'pdv-rupture-table'" fgsColor="#ff7900" pbColor="#ff7900"></ngx-ui-loader>
</div>
