import {Component, Input, OnInit, ViewChild} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import {filter, finalize, map, Observable, of, Subject, switchMap, tap} from 'rxjs';
import { IDistributeurStock } from 'src/app/interface/distributeur.interface';
import { ITerritoireStock } from 'src/app/interface/territoire.interface';
import { IModalPDVRuptureDatas } from 'src/app/interface/utils.interface';
import { DashboardService } from 'src/app/services/dashboard/dashboard.service';
import { LastUpdateDataService } from 'src/app/services/last-update-data/last-update-data.service';
import { PdvService } from 'src/app/services/pdv/pdv.service';
import { UtilitiesService } from 'src/app/services/utilities/utilities.service';
import { FiltersService } from 'src/app/views/authenticated/pages/stock-level/filters/filters.service';
import * as FileSaver from "file-saver";
import {DataTableDirective} from "angular-datatables";

@Component({
  selector: 'app-modal-pdv-rupture',
  templateUrl: './modal-pdv-rupture.component.html',
  styleUrls: ['./modal-pdv-rupture.component.scss']
})
export class ModalPdvRuptureComponent implements OnInit {
  modalTitle: string = ""
  modalType: string = ""
  tableItems: Array<any> = []
  tableLabels: Array<any> = []
  mostGreaterValue: number;
  dtOptions: any = {};
  dtTrigger: Subject<any> = new Subject<any>();
  @ViewChild(DataTableDirective, { static: false })
  pdvDtElement: DataTableDirective;
  pdvDtOptions: any = {};
  pdvDtTrigger: Subject<any> = new Subject<any>();
  datatableIsInitialized: boolean = false;
  lastStartDate: string = ''
  lastEndDate: string = ''
  lastHour: { start: string, end: string } = { start: "", end: "" }
  isLastMonth: boolean = false
  isLastDate: boolean = true
  totalItems: number = 0;
  currentPage: number = 0;
  itemPerPage: number = 20;
  globalDataFilterParams = {
    regions: [] as string[],
    territoires: [] as string[],
    distributeurs: [] as string[],
    pdvs: [] as string[],
  } as any
  permissionName: string = "PAGE_PDV_RUPTURE_EXPORT_VUE_TERRITOIRE"
  pdvSearchTerm: string = ""
  @Input() datas: any;
  constructor(
    public activeModal: NgbActiveModal,
    private utilitiesService: UtilitiesService,
    private pdvService: PdvService,
    private toastr: ToastrService,
    private lastUpdateDataService: LastUpdateDataService,
    private filtersService: FiltersService,
    private ngxUiLoaderService: NgxUiLoaderService,
    private dashboardService: DashboardService
  ) { }

  ngOnInit(): void {
    /*this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 20,
      serverSide: true,
      processing: true,
      lengthMenu: [20, 50, 100],
      language: {
        url: '/assets/i18n/datatable.fr.json',
        search: 'Rechercher'
      }
    }*/
    if (this.datas) {
      const datasTyped = <IModalPDVRuptureDatas>this.datas
      this.modalTitle = datasTyped.modalTitle
      this.modalType = datasTyped.modalType
      this.lastStartDate = datasTyped.lastStartDate
      this.lastEndDate = datasTyped.lastEndDate
      this.lastHour = datasTyped.lastHour
      this.isLastDate = datasTyped.isLastDate
      this.globalDataFilterParams = datasTyped.globalDataFilterParams
    }
    switch (this.modalType) {
      case 'territoire':
        this.permissionName = "PAGE_PDV_RUPTURE_EXPORT_VUE_TERRITOIRE"
        break;
      case 'locality':
        this.permissionName = "PAGE_PDV_RUPTURE_EXPORT_VUE_TERRITOIRE"
        break;
      case 'distributeur':
        this.permissionName = "PAGE_PDV_RUPTURE_EXPORT_VUE_DISTRIBUTEUR"
        break;

      default:
        break;
    }

    this.renderDatatable()
  }

  initDTOptions(){
    const that = this;
    this.pdvDtOptions = {
      pagingType: 'full_numbers',
      pageLength: 20,
      serverSide: true,
      processing: true,
      lengthMenu: [20, 50, 100],
      columns: this.getDatatableColumns(),
      ajax: (dataTablesParameters: any, callback: any) => {
        that.ngxUiLoaderService.startLoader('pdv-rupture-table')
        that.pdvSearchTerm = dataTablesParameters.search.value
        that.getDataForModalType(
          dataTablesParameters.search.value,
          dataTablesParameters.start,
          dataTablesParameters.length,
        ).pipe(
          filter(response => !response.hasError && "items" in response && Array.isArray(response.items) && response.items.length > 0),
          finalize(() => that.ngxUiLoaderService.stopLoader('pdv-rupture-table'))
        ).subscribe({
          next: (response) => {
            that.totalItems = response.count
            const responseItems = response.items.map((elem: any) => {
              return {
                ...elem[Object.keys(elem)[0]],
                libelle: Object.keys(elem)[0],
              }
            })
            callback({
              recordsTotal: response.count,
              recordsFiltered: response.count,
              data: responseItems.length > dataTablesParameters.length ? responseItems.slice(0, dataTablesParameters.length) : responseItems
            });
          },
          error: () => {
            callback({
              recordsTotal: 0,
              recordsFiltered: 0,
              data: []
            });
          },
        })
        /* that.approvisionnementUvService.getDataByType(
          ShowAllType.PDVS,
          {
            index: dataTablesParameters.start / dataTablesParameters.length,
            size: dataTablesParameters.length,
            search: dataTablesParameters.search.value
          }
        ).pipe(
          map(response => {
            // this.fillPDVTableEmptyColumns(response)
            return response
          }),
          finalize(() => this.ngxUiLoaderService.stopLoader('pdv-kpi-table'))
        ).subscribe({
          next: response => {
            this.pdvKPI.hasData = response.items.length > 0
            this.pdvKPI.amountTotal = response.montantTotalPdv ?? '0'
            callback({
              recordsTotal: response.count,
              recordsFiltered: response.count,
              data: response.items.length > dataTablesParameters.length ? response.items.slice(0, dataTablesParameters.length) : response.items
            });
          },
          error: error => {
            callback({
              recordsTotal: 0,
              recordsFiltered: 0,
              data: []
            });
          }
        });*/
      },
      language: {
        url: '/assets/i18n/datatable.fr.json',
        search: 'Rechercher'
      }
    }
  }
  renderDatatable(){
    if(this.pdvDtElement?.dtInstance) {
      this.pdvDtElement.dtInstance.then(dtInstance => {
        dtInstance.destroy();
      });
    }
    this.initDTOptions()
    setTimeout(() => {
      this.pdvDtTrigger.next(this.pdvDtOptions);
    }, 100);
  }

  getDataForModalType(
    searchValue: string,
    index: number,
    size: number
  ): Observable<any> {
    const dataFetchers: { [key: string]: Observable<any> } = {
      'territoire': this.getTerritoryData(index, size, searchValue),
      'locality': this.getLocalityData(index, size, searchValue),
      'distributeur': this.getDealerData(index, size, searchValue)
    };

    return dataFetchers[this.modalType] ?? of({ hasError: true, message: 'No data found for the provided modal type.' });
  }

  getTerritoryData(index: number = 0, size: number = 20, searchValue: string): Observable<any> {
    return this.pdvService.getAllTerritoireStock(this.lastStartDate, this.lastEndDate, this.lastHour, this.isLastDate, this.isLastMonth, index, size, searchValue, this.globalDataFilterParams)
    /*return this.pdvService.getAllTerritoireStock(this.lastStartDate, this.lastEndDate, this.lastHour, this.isLastDate, this.isLastMonth, index, size, this.globalDataFilterParams).subscribe({
      next: (response: any) => {
        this.tableItems = []
        if (!response.hasError && response.hasOwnProperty('items')) {
          //Transform Data
          if (response.items.length > 0) {
            this.totalItems = response.count
            response.items.map((elem: any) => {
              let tmpItem = {} as ITerritoireStock
              tmpItem = {
                ...elem[Object.keys(elem)[0]],
                territoireName: Object.keys(elem)[0]
              }
              this.tableItems.push(tmpItem)
            })
          }
          this.defineMostGreaterStocksValues()
          this.ngxUiLoaderService.stopLoader('pdv-rupture-table')
          if (!this.datatableIsInitialized) {
            this.dtTrigger.next(this.dtOptions)
            this.datatableIsInitialized = true
          }
          // return;
        } else {
          this.toastr.error('Oops! Nous ne parvenons pas à récupérer les stocks des territoires', 'Information');
          //this.authenticationFailed = true
        }
      },
      error: (error: any) => {
        this.toastr.error('Oops! Une erreur inattendue a été rencontrée.', 'Error');
        throw new Error(error)
      }
    })*/
  }

  getLocalityData(index: number = 0, size: number = 20, searchValue: string) {
    return this.pdvService.getAllLocalityStock(this.lastStartDate, this.lastEndDate, this.lastHour, this.isLastDate, this.isLastMonth, index, size, searchValue, this.globalDataFilterParams)
    /* return this.pdvService.getAllLocalityStock(this.lastStartDate, this.lastEndDate, this.lastHour, this.isLastDate, this.isLastMonth, index, size, this.globalDataFilterParams).subscribe({
      next: (response: any) => {
        this.tableItems = []
        if (!response.hasError && response.hasOwnProperty('items')) {
          //Transform Data
          if (response.items.length > 0) {
            this.totalItems = response.count
            response.items.map((elem: any) => {
              let tmpItem = {} as ITerritoireStock
              tmpItem = {
                ...elem[Object.keys(elem)[0]],
                localityName: Object.keys(elem)[0]
              }
              this.tableItems.push(tmpItem)
            })
          }
          this.defineMostGreaterStocksValues()
          this.ngxUiLoaderService.stopLoader('pdv-rupture-table')
          if (!this.datatableIsInitialized) {
            this.dtTrigger.next(this.dtOptions)
            this.datatableIsInitialized = true
          }
          // return;
        } else {
          this.toastr.error('Oops! Nous ne parvenons pas à récupérer les stocks des localités', 'Information');
          //this.authenticationFailed = true
        }
      },
      error: (error: any) => {
        this.toastr.error('Oops! Une erreur inattendue a été rencontrée.', 'Error');
        throw new Error(error)
      }
    })*/
  }

  getDealerData(index: number = 0, size: number = 20, searchValue: string) {
    return this.pdvService.getAllDistributeurStock(this.lastStartDate, this.lastEndDate, this.lastHour, this.isLastDate, this.isLastMonth, index, size, searchValue, this.globalDataFilterParams)
    /* return this.pdvService.getAllDistributeurStock(this.lastStartDate, this.lastEndDate, this.lastHour, this.isLastDate, this.isLastMonth, index, size, this.globalDataFilterParams).subscribe({
      next: (response: any) => {
        this.tableItems = []
        if (!response.hasError && response.hasOwnProperty('items')) {
          //Transform Data
          if (response.items.length > 0) {
            response.items.map((elem: any) => {
              let tmpItem = {} as IDistributeurStock
              tmpItem = {
                ...elem[Object.keys(elem)[0]],
                numero: Object.keys(elem)[0]
              }
              this.tableItems.push(tmpItem)
            })
          }
        }
      },
      error: (error: any) => {
        this.toastr.error('Oops! Une erreur inattendue a été rencontrée.', 'Error');
        throw new Error(error)
      }
    })*/
  }

  defineMostGreaterStocksValues() {
    const returnStockStatValue = (item: any) => item.dealerDistNbRupture ? Number(item.dealerDistNbRupture.replace(' ', '').replace(',', '.')) : 0

    this.mostGreaterValue = Math.max(
      ...this.tableItems.map(returnStockStatValue)
    )
  }

  /**
   * Fefine Style with width property of stats gauge calculate with current value and the max value
   *
   * @param value
   * @param mostGreaterStockValue
   * @returns
   */
  defineWidthStockGauge(value: number | string, mostGreaterStockValue: number): string {
    if (typeof value === 'string') {
      value = Number(value.replace(' ', '').replace(',', '.'))
    }
    return `${value * 100 / mostGreaterStockValue}%`
  }

  export(dataType: string): void {
    this.ngxUiLoaderService.startLoader('pdv-rupture-table')

    switch (dataType) {
      case 'region':
        this.exportData(dataType, 'dashbord/exportVueRegionRupture')
        break;
      case 'territoire':
        this.exportData(dataType, 'dashbord/exportVueTerritoireRupture')
        break;
      case 'distributeur':
        this.exportData(dataType, 'dashbord/exportVueDistributeurRupture')
        break;
      case 'pdv_agent':
        this.exportData(dataType, 'omerPdvHist/exportPdv')
        break;

      default:
        break;
    }
  }

  exportData(dataType: string, endpoint: string): void {
    this.dashboardService.exportPdvData(
      endpoint,
      this.lastStartDate,
      this.lastEndDate,
      this.lastHour,
      this.isLastDate,
      this.isLastMonth,
      this.currentPage,
      this.itemPerPage,
      this.globalDataFilterParams
    )
    .pipe(
      finalize(() => this.ngxUiLoaderService.stopLoader('pdv-rupture-table')),
    )
    .subscribe({
      next: (response: any) => {
        if(!(response instanceof ArrayBuffer) || ('hasError' in response && response.hasError)){
          this.toastr.error('Oops! Une erreur inattendue a été rencontrée lors de l\'export des données.', 'Error')
          return;
        }
        const file = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const fileName = "omerobf" + '_export_' + new Date().getTime()
        FileSaver.saveAs(file, `${fileName}.xlsx`);
      },
      error: (error: any) => {
        this.ngxUiLoaderService.stopLoader('pdv-rupture-table')
        this.toastr.error('Oops! Une erreur inattendue a été rencontrée.', 'Error');
        throw new Error(error)
      }
    })
  }

  private getDatatableColumns() {
    const modalTypeLabelMapping = {
      'territoire': {title: 'Territoires', data: 'libelle'},
      'locality': {title: 'Localité', data: 'libelle'},
      'distributeur': {title: 'NUMERO DIST', data: 'libelle'}
    }
    const firstColumn = modalTypeLabelMapping[this.modalType] ?? {title: this.modalType, data: this.modalType}
    let extraColumn: boolean | Record<string, string> = false
    if(this.modalType === "distributeur"){
      extraColumn = {
        title: 'Nom & Prénom(s)',
        data: 'distributeur',
      }
    }
    return [
      firstColumn,
      ...(extraColumn ? [extraColumn] : []),
      {
        title: 'Nb banque en rupture <br>( FCFA TTC )',
        data: null,
        render: (data: any, type: any, row: any, meta: any) => 'bankDistNbRupture' in row ? row.bankDistNbRupture : 0
      },
      {
        title: 'Nb Distributeur en rupture <br>( FCFA TTC )',
        data: null,
        render: (data: any, type: any, row: any, meta: any) => 'dealerDistNbRupture' in row ? this.renderRowWithGauge(row, meta, 'dealerDistNbRupture') : 0
      },
      {
        title: 'Nb Dealer agents non payables en rupture <br>( FCFA TTC )',
        data: null,
        render: (data: any, type: any, row: any, meta: any) => 'dealerNonPayDistNbRupture' in row ? row.dealerNonPayDistNbRupture : 0
      },
      {
        title: 'Nb autre Distributeur en rupture <br>( FCFA TTC )',
        data: null,
        render: (data: any, type: any, row: any, meta: any) => 'otherDistNbRupture' in row ? row.otherDistNbRupture : 0
      },
      {title: 'Nb PDV rattachés aux Banque  en rupture <br>( FCFA TTC )', data: 'pdvBankNbRupture'},
      {title: 'Nb PDV rattachés aux distributeurs  en rupture <br>( FCFA TTC )', data: 'pdvDistNbRupture'},
      {title: 'Nb PDV rattachés aux Dealer agents non payables  en rupture <br>( FCFA TTC )', data: 'pdvDistNonPayNbRupture'},
      {title: 'Nb Autre PDV en rupture<br>( FCFA TTC )', data: 'pdvOtherNbRupture'},
      {title: 'Nb Banque Agent en rupture <br>( FCFA TTC )', data: 'agentBankNbRupture'},
      {title: 'Nb Agent relai en rupture <br>( FCFA TTC )', data: 'agentRelaiNbRupture'},
      {title: 'Nb Agent Super relai en rupture <br>( FCFA TTC )', data: 'agentSuperRelaiNbRupture'},
    ]
  }

  private getLabelKeyName(){
    const labelKeyNameMapping = {
      'territoire': 'territoryName',
      'locality': 'localityName',
      'distributeur': 'numero'
    }
    return labelKeyNameMapping[this.modalType] ?? 'libelle'
  }

  private renderRowWithGauge(row: any, meta: any, key: string) {
    if(!(key in row)) return ''

    const rowData = typeof row[key] === 'string' ? this.utilitiesService.transformStringToNumber(row[key]) : row[key];
    const maxValue = Math.max(
      ...meta.settings.aoData.map(item => this.utilitiesService.transformStringToNumber(item._aData[key]))
    )
    const itemsValueSum = meta.settings.aoData.reduce((acc, item) => acc + this.utilitiesService.transformStringToNumber(item._aData[key]), 0)
    const gaugePercentage = `${(rowData / itemsValueSum) * 100}%`;
    const gaugeWidth = `${rowData * 100 / maxValue}%`
    return `<span class="bg__jauge" style="--jauge-width: ${gaugeWidth}">${row[key]}</span>`;
  }

}
