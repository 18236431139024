import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IDistributeur } from 'src/app/interface/distributeur.interface';
import { IHeureParam } from 'src/app/interface/heureParam.interface';
import { RestClientService } from '../rest-client.service';
import { UserService } from '../user/user.service';
import { IPdvAgentResponseData, ISegmentResponseData, ITauxRuptureDistributeurResponseData } from 'src/app/interface/pdv.interface';
import dayjs from 'dayjs/esm';
@Injectable({
  providedIn: 'root'
})
export class PdvService {
  private readonly basePath: string = 'pdv'
  private readonly historicBasePath: string = 'omerPdvHist'
  private nombreRuptureBody = {
    "user": "",
    "isLastDate": false,
    "data": {
      "dateDebut": "",
      "dateFin": "",
      "heureParam": {
        "start": "",
        "end": ""
      },
      "isActif30j": true,
      "regions": [] as string[] | 'all',
      "territoires": [] as string[] | 'all',
      "distributeurs": [] as string[] | 'all',
      msisdn: "",
      msisdn_param: {
        operator: "IN",
        datas: [] as string[] | 'all'
      },
      categorie_DISTRIBUTEUR_param: {} as {
        operator: string,
        datas: string[]
      },
      categorie_PDV_param: {} as {
        operator: string,
        datas: string[]
      }
    }
  }
  private readonly defaultBody: {
    id: string,
    code: string,
    libelle: string,
    createdAt: string,
    updatedAt: string,
    deletedAt: string,
    createdBy: string,
    updatedBy: string,
    deletedBy: string,
    isDeleted: string,
    distributeurs?: string[],
    msisdn?: string;
    msisdn_param?: {
      operator: string,
      datas?: string[] | 'all'
    };
    categorie_DISTRIBUTEUR_param?: {
      operator: string,
      datas: string[]
    };
    categorie_PDV_param?: {
      operator: string,
      datas: string[]
    }
  } = {
      id: "",
      code: "",
      libelle: "",
      createdAt: "",
      updatedAt: "",
      deletedAt: "",
      createdBy: "",
      updatedBy: "",
      deletedBy: "",
      isDeleted: "",
    };
  constructor(
    private user: UserService,
    private restClient: RestClientService
  ) { }

  get(): Observable<any> {
    const bodyDatas = {
      "user": this.user.userMatricule,
      "data": { ...this.defaultBody }
    }
    return this.restClient.post(`${this.basePath}/getByCriteria`, bodyDatas)
  }

  searchByDistributors(term: string, distributors: string[] | 'all', isActivethirtyDaysFilter: boolean): Observable<any> {
    let bodyDatas: {
      user: string;
      data: {
        orderField: string;
        orderDirection: string;
        isActif30j: boolean;
        numero_DISTRIBUTEUR_param?: {
          operator: string;
          datas: string[] | 'all';
        };
        msisdn?: string;
        msisdn_param?: {
          operator: string,
          datas?: string[] | 'all'
        };
      }
    } = {
      user: this.user.userMatricule,
      data: {
        orderField: "FULL_DATE",
        orderDirection: "DESC",
        isActif30j: isActivethirtyDaysFilter
      }
    }
    if (distributors.length > 0) {
      bodyDatas.data.numero_DISTRIBUTEUR_param = {
        operator: "IN",
        datas: distributors
      }
    }
    if (term != '') {
      bodyDatas.data.msisdn = term
      bodyDatas.data.msisdn_param = {
        operator: "%%",
      }
    }
    return this.restClient.post<{
      hasError: boolean,
      sumTotalDistributeur: number,
      sumTotalPdv: number,
      sumTotalDistributeurPlain: string,
      sumTotalPdvPlain: string
    }>('dashbord/getListPdv', bodyDatas)
  }
  getLastStockUpdate(): Observable<any> {
    const bodyDatas = {
      "user": this.user.userMatricule,
      "data": {}
    }
    return this.restClient.post(`${this.historicBasePath}/getLastHour`, bodyDatas)
  }
  getPdvHistoric(
    startDate: string = '',
    endDate: string = '',
    hour: IHeureParam,
    isLastDate: boolean = false,
    isLastMonth: boolean = false,
    globalDataFilterParams: { regions: Array<string> | 'all', territoires: Array<string> | 'all', distributeurs: Array<string> | 'all', pdvs: Array<string> | 'all', categorie_DISTRIBUTEUR_param?: string[], categorie_PDV_param?: string[] },
    index: number = 0,
    size: number = 10,
    searchParam: string
  ): Observable<IPdvAgentResponseData> {
    let bodyDatas = {
      "index": index,
      "size": size,
      "isSum": true,
      "user": this.user.userMatricule,
      isLastDate,
      isActif30j: isLastMonth,
      "data": {
        dateDebut: startDate,
        dateFin: endDate,
        heureParam: hour,
        nom_AGENT: searchParam.length > 0 ? searchParam : "",
        pdv_EN_RUPTURE: true,
        categorie_DISTRIBUTEUR_param: {} as {
          operator: string,
          datas: string[]
        },
        categorie_PDV_param: {} as {
          operator: string,
          datas: string[]
        }
      } as Record<string, any>
    }

    if (globalDataFilterParams.regions.length !== 0) {
      bodyDatas.data = {
        ...bodyDatas.data,
        region_param: {
          "operator": "IN",
          "datas": globalDataFilterParams.regions
        }
      }
    }
    if (globalDataFilterParams.territoires.length !== 0) {
      bodyDatas.data = {
        ...bodyDatas.data,
        territoire_param: {
          "operator": "IN",
          "datas": globalDataFilterParams.territoires
        }
      }
    }

    if (globalDataFilterParams.distributeurs.length !== 0) {
      bodyDatas.data = {
        ...bodyDatas.data,
        numero_DISTRIBUTEUR_param: {
          "operator": "IN",
          "datas": globalDataFilterParams.distributeurs
        }
      }
    }
    if (globalDataFilterParams.pdvs.length !== 0) {
      bodyDatas.data = {
        ...bodyDatas.data,
        msisdn_param: {
          operator: "IN",
          datas: globalDataFilterParams.pdvs
        }
      }
    }
    if(globalDataFilterParams.categorie_DISTRIBUTEUR_param && globalDataFilterParams.categorie_DISTRIBUTEUR_param.length > 0) {
      bodyDatas.data = {
        ... bodyDatas.data,
        categorie_DISTRIBUTEUR_param: {
          operator: "IN",
          datas: globalDataFilterParams.categorie_DISTRIBUTEUR_param
        }
      }
    }

    if(globalDataFilterParams.categorie_PDV_param && globalDataFilterParams.categorie_PDV_param.length > 0) {
      bodyDatas.data = {
        ... bodyDatas.data,
        categorie_PDV_param: {
          operator: "IN",
          datas: globalDataFilterParams.categorie_PDV_param
        }
      }
    }
    return this.restClient.post(`${this.historicBasePath}/getByCriteria`, bodyDatas)
  }
  getTauxRupturePdvHistoric(
    startDate: string = '',
    endDate: string = '',
    hour: IHeureParam,
    isLastDate: boolean = false,
    isLastMonth: boolean = false,
    globalDataFilterParams: { regions: Array<string> | 'all', territoires: Array<string> | 'all', distributeurs: Array<string> | 'all', pdvs: string[] | 'all', categorie_DISTRIBUTEUR_param?: string[], categorie_PDV_param?: string[] },
    size: number = 10,
    index: number = 0,
    searchParam: string
  ): Observable<ITauxRuptureDistributeurResponseData> {
    let bodyDatas = {
      "index": index,
      "size": size,
      "isSum": true,
      "user": this.user.userMatricule,
      "orderField": "FULL_DATE",
      "orderDirection": "DESC",
      isLastDate,
      isActif30j: isLastMonth,
      "data": {
        "user": this.user.userMatricule,
        isActif30j: isLastMonth,
        date_param: {
          operator: "[]",
          start: dayjs(startDate, 'DD/MM/YYYY').format('YYYY-MM-DD'),
          end: dayjs(endDate, 'DD/MM/YYYY').format('YYYY-MM-DD')
        },
        heure_param: {
          "operator": "[]",
          "start": hour.start,
          "end": hour.end
        }
      } as Record<string, any>
    }
    if (globalDataFilterParams.regions.length !== 0) {
      bodyDatas.data = {
        ...bodyDatas.data,
        region_param: {
          "operator": "IN",
          "datas": globalDataFilterParams.regions
        }
      }
    }
    if (globalDataFilterParams.territoires.length !== 0) {
      bodyDatas.data = {
        ...bodyDatas.data,
        territoire_param: {
          "operator": "IN",
          "datas": globalDataFilterParams.territoires
        }
      }
    }
    if (globalDataFilterParams.distributeurs.length !== 0) {
      bodyDatas.data = {
        ...bodyDatas.data,
        numero_DISTRIBUTEUR_param: {
          "operator": "IN",
          "datas": globalDataFilterParams.distributeurs
        }
      }
    }

    if (globalDataFilterParams.pdvs.length !== 0) {
      bodyDatas.data = {
        ...bodyDatas.data,
        msisdn_param: {
          "operator": "IN",
          "datas": globalDataFilterParams.pdvs
        }
      }
    }
    if (searchParam.length !== 0) {
      bodyDatas.data = {
        ...bodyDatas.data,
        numero_DISTRIBUTEUR: searchParam
      }
    }
    return this.restClient.post(`omerDistHist/getByCriteria`, bodyDatas)
  }
  findOne(id: number): Observable<any> {
    const data = { ...this.defaultBody }
    data.id = id.toString()
    const datas = {
      "user": this.user.userMatricule,
      "data": data
    }
    return this.restClient.post(`${this.basePath}/getByCriteria`, datas)
  }
  create(datas: IDistributeur): Observable<any> {
    const bodyDatas = {
      "user": this.user.userMatricule,
      "datas": [datas]
    }
    return this.restClient.post(`${this.basePath}/create`, bodyDatas)
  }
  update(datas: IDistributeur): Observable<any> {
    const bodyDatas = {
      "user": this.user.userMatricule,
      "datas": [datas]
    }
    return this.restClient.post(`${this.basePath}/update`, bodyDatas)
  }
  upload(file: File) {
    let formParams = new FormData();
    formParams.append('file', file)
    formParams.append('user', this.user.userMatricule)

    return this.restClient.post(`${this.basePath}/uploadDistributeur`, formParams, { "Content-Type": "multipart/form-data" })
  }
  getUploadedFilePath(): Observable<any> {
    return this.restClient.get(`${this.basePath}/getGeojsonFile`)
  }
  delete(id: number): Observable<any> {
    const bodyDatas = {
      "user": this.user.userMatricule,
      "datas": [{ id }]
    }
    return this.restClient.post(`${this.basePath}/delete`, bodyDatas)
  }
  /* getByDistributor(distributorId: string): Observable<any>{
    const data = { ...this.defaultBody }
    data.distributorId = distributorId
    const bodyDatas = {
      "user": this.user.userMatricule,
      "data": { data }
    }
    return this.restClient.post(`${this.basePath}/getByCriteria`, bodyDatas)
  } */

  getAllRegionsNombreRupture(startDate: string = '', endDate: string = '', hour: { start: string, end: string }, isLastDate: boolean = false, isLastMonth: boolean = false, globalDataFilterParams: { regions: string[] | 'all', territoires: string[] | 'all', distributeurs: string[] | 'all', pdvs: string[] | 'all', categorie_DISTRIBUTEUR_param?: string[], categorie_PDV_param?: string[] }) {
    const bodyDatas = { ...this.nombreRuptureBody }
    bodyDatas.user = this.user.userMatricule
    bodyDatas.isLastDate = isLastDate
    if (!isLastDate) {
      bodyDatas.data.dateDebut = startDate
      bodyDatas.data.dateFin = endDate
      bodyDatas.data.heureParam = hour
    }

    bodyDatas.data.isActif30j = isLastMonth
    bodyDatas.data.regions = globalDataFilterParams.regions
    bodyDatas.data.territoires = globalDataFilterParams.territoires
    bodyDatas.data.distributeurs = globalDataFilterParams.distributeurs
    if(globalDataFilterParams.categorie_DISTRIBUTEUR_param && globalDataFilterParams.categorie_DISTRIBUTEUR_param.length > 0) {
      bodyDatas.data.categorie_DISTRIBUTEUR_param = {
        operator: "IN",
        datas: globalDataFilterParams.categorie_DISTRIBUTEUR_param
      }
    }

    if(globalDataFilterParams.categorie_PDV_param && globalDataFilterParams.categorie_PDV_param.length > 0) {
      bodyDatas.data.categorie_PDV_param = {
        operator: "IN",
        datas: globalDataFilterParams.categorie_PDV_param
      }
    }
    if (globalDataFilterParams.pdvs.length !== 0) {
      bodyDatas.data = {
        ...bodyDatas.data,
        msisdn_param: {
          operator: "IN",
          datas: globalDataFilterParams.pdvs
        }
      }
    }
    return this.restClient.post('dashbord/regionDetailsNbreRupture', bodyDatas)
  }

  getAllTerritoireStock(startDate: string = '', endDate: string = '', hour: { start: string, end: string }, isLastDate: boolean = false, isLastMonth: boolean = false, index: number = 0, size: number = 20,searchValue: string, globalDataFilterParams: { regions: Array<string> | 'all', territoires: Array<string> | 'all', distributeurs: Array<string> | 'all', pdvs: string[] | 'all', categorie_DISTRIBUTEUR_param?: string[], categorie_PDV_param?: string[] }) {
    const bodyDatas = JSON.parse(JSON.stringify(this.nombreRuptureBody));
    if ((size && index) || (size !== 0)) {
      Object.assign(bodyDatas, { size: size, index: index })
    }
    bodyDatas.user = this.user.userMatricule
    bodyDatas.isLastDate = isLastDate
    bodyDatas.data.key = searchValue
    if (!isLastDate) {
      bodyDatas.data.dateDebut = startDate
      bodyDatas.data.dateFin = endDate
      bodyDatas.data.heureParam = hour
    }

    bodyDatas.data.regions = globalDataFilterParams.regions
    bodyDatas.data.territoires = globalDataFilterParams.territoires
    bodyDatas.data.distributeurs = globalDataFilterParams.distributeurs
    if(globalDataFilterParams.categorie_DISTRIBUTEUR_param && globalDataFilterParams.categorie_DISTRIBUTEUR_param.length > 0) {
      bodyDatas.data.categorie_DISTRIBUTEUR_param = {
        operator: "IN",
        datas: globalDataFilterParams.categorie_DISTRIBUTEUR_param
      }
    }

    if(globalDataFilterParams.categorie_PDV_param && globalDataFilterParams.categorie_PDV_param.length > 0) {
      bodyDatas.data.categorie_PDV_param = {
        operator: "IN",
        datas: globalDataFilterParams.categorie_PDV_param
      }
    }
    if (globalDataFilterParams.pdvs.length !== 0) {
      bodyDatas.data = {
        ...bodyDatas.data,
        msisdn_param: {
          operator: "IN",
          datas: globalDataFilterParams.pdvs
        }
      }
    }
    bodyDatas.data.isActif30j = isLastMonth
    return this.restClient.post('dashbord/territoireDetailsNbreRupture', bodyDatas)
  }

  getAllLocalityStock(startDate: string = '', endDate: string = '', hour: { start: string, end: string }, isLastDate: boolean = false, isLastMonth: boolean = false, index: number = 0, size: number = 20, searchValue: string, globalDataFilterParams: { regions: Array<string> | 'all', territoires: Array<string> | 'all', distributeurs: Array<string> | 'all', pdvs: Array<string> | 'all', categorie_DISTRIBUTEUR_param?: string[], categorie_PDV_param?: string[] }) {
    const bodyDatas = JSON.parse(JSON.stringify(this.nombreRuptureBody));
    if ((size && index) || (size !== 0)) {
      Object.assign(bodyDatas, { size: size, index: index })
    }
    bodyDatas.user = this.user.userMatricule
    bodyDatas.isLastDate = isLastDate
    bodyDatas.data.key = searchValue
    if (!isLastDate) {
      bodyDatas.data.dateDebut = startDate
      bodyDatas.data.dateFin = endDate
      bodyDatas.data.heureParam = hour
    }

    bodyDatas.data.regions = globalDataFilterParams.regions
    bodyDatas.data.territoires = globalDataFilterParams.territoires
    bodyDatas.data.distributeurs = globalDataFilterParams.distributeurs
    if(globalDataFilterParams.categorie_DISTRIBUTEUR_param && globalDataFilterParams.categorie_DISTRIBUTEUR_param.length > 0) {
      bodyDatas.data.categorie_DISTRIBUTEUR_param = {
        operator: "IN",
        datas: globalDataFilterParams.categorie_DISTRIBUTEUR_param
      }
    }

    if(globalDataFilterParams.categorie_PDV_param && globalDataFilterParams.categorie_PDV_param.length > 0) {
      bodyDatas.data.categorie_PDV_param = {
        operator: "IN",
        datas: globalDataFilterParams.categorie_PDV_param
      }
    }
    bodyDatas.data.isActif30j = isLastMonth
    return this.restClient.post('dashbord/localiteDetailsNbreRupture', bodyDatas)
  }

  getAllDistributeurStock(startDate: string = '', endDate: string = '', hour: { start: string, end: string }, isLastDate: boolean = false, isLastMonth: boolean = false, index: number = 0, size: number = 20, searchValue: string, globalDataFilterParams: { regions: Array<string> | 'all', territoires: Array<string> | 'all', distributeurs: Array<string> | 'all', pdvs: string[] | 'all', categorie_DISTRIBUTEUR_param?: string[], categorie_PDV_param?: string[] }) {
    const bodyDatas = JSON.parse(JSON.stringify(this.nombreRuptureBody));
    if ((size && index) || (size !== 0)) {
      Object.assign(bodyDatas, { size: size, index: index })
    }
    bodyDatas.data.key = searchValue
    bodyDatas.user = this.user.userMatricule
    bodyDatas.isLastDate = isLastDate
    if (!isLastDate) {
      bodyDatas.data.dateDebut = startDate
      bodyDatas.data.dateFin = endDate
      bodyDatas.data.heureParam = hour
    }

    bodyDatas.data.isActif30j = isLastMonth
    bodyDatas.data.regions = globalDataFilterParams.regions
    bodyDatas.data.territoires = globalDataFilterParams.territoires
    bodyDatas.data.distributeurs = globalDataFilterParams.distributeurs
    if(globalDataFilterParams.categorie_DISTRIBUTEUR_param && globalDataFilterParams.categorie_DISTRIBUTEUR_param.length > 0) {
      bodyDatas.data.categorie_DISTRIBUTEUR_param = {
        operator: "IN",
        datas: globalDataFilterParams.categorie_DISTRIBUTEUR_param
      }
    }

    if(globalDataFilterParams.categorie_PDV_param && globalDataFilterParams.categorie_PDV_param.length > 0) {
      bodyDatas.data.categorie_PDV_param = {
        operator: "IN",
        datas: globalDataFilterParams.categorie_PDV_param
      }
    }
    if (globalDataFilterParams.pdvs.length !== 0) {
      bodyDatas.data = {
        ...bodyDatas.data,
        msisdn_param: {
          operator: "IN",
          datas: globalDataFilterParams.pdvs
        }
      }
    }
    return this.restClient.post('dashbord/distributeurDetailsNbreRupture', bodyDatas)
  }

  getRegionStock(startDate: string = '', endDate: string = '', hour: { start: string, end: string }, isLastDate: boolean = false, isLastMonth: boolean = false, globalDataFilterParams: { regions: Array<string> | 'all', territoires: Array<string> | 'all', distributeurs: Array<string> | 'all', pdvs: string[] | 'all', categorie_DISTRIBUTEUR_param?: string[], categorie_PDV_param?: string[] }) {
    const bodyDatas = { ...this.nombreRuptureBody }
    bodyDatas.user = this.user.userMatricule
    bodyDatas.isLastDate = isLastDate
    if (!isLastDate) {
      bodyDatas.data.dateDebut = startDate
      bodyDatas.data.dateFin = endDate
      bodyDatas.data.heureParam = hour
    }
    bodyDatas.data.isActif30j = isLastMonth
    bodyDatas.data.regions = globalDataFilterParams.regions
    bodyDatas.data.territoires = globalDataFilterParams.territoires
    bodyDatas.data.distributeurs = globalDataFilterParams.distributeurs
    if(globalDataFilterParams.categorie_DISTRIBUTEUR_param && globalDataFilterParams.categorie_DISTRIBUTEUR_param.length > 0) {
      bodyDatas.data.categorie_DISTRIBUTEUR_param = {
        operator: "IN",
        datas: globalDataFilterParams.categorie_DISTRIBUTEUR_param
      }
    }

    if(globalDataFilterParams.categorie_PDV_param && globalDataFilterParams.categorie_PDV_param.length > 0) {
      bodyDatas.data.categorie_PDV_param = {
        operator: "IN",
        datas: globalDataFilterParams.categorie_PDV_param
      }
    }

    if (globalDataFilterParams.pdvs.length !== 0) {
      bodyDatas.data = {
        ...bodyDatas.data,
        msisdn_param: {
          operator: "IN",
          datas: globalDataFilterParams.pdvs
        }
      }
    }

    return this.restClient.post('dashbord/totalRegion', bodyDatas)
  }

  getTerritoireStock(startDate: string = '', endDate: string = '', hour: { start: string, end: string }, isLastDate: boolean = false, isLastMonth: boolean = false, globalDataFilterParams: { regions: Array<string> | 'all', territoires: Array<string> | 'all', distributeurs: Array<string> | 'all', pdvs: string[] | 'all', categorie_DISTRIBUTEUR_param?: string[], categorie_PDV_param?: string[] }) {
    const bodyDatas = { ...this.nombreRuptureBody }
    bodyDatas.user = this.user.userMatricule
    bodyDatas.isLastDate = isLastDate
    if (!isLastDate) {
      bodyDatas.data.dateDebut = startDate
      bodyDatas.data.dateFin = endDate
      bodyDatas.data.heureParam = hour
    }
    bodyDatas.data.isActif30j = isLastMonth
    bodyDatas.data.regions = globalDataFilterParams.regions
    bodyDatas.data.territoires = globalDataFilterParams.territoires.length === 0 ? [] : globalDataFilterParams.territoires
    bodyDatas.data.distributeurs = globalDataFilterParams.distributeurs.length === 0 ? [] : globalDataFilterParams.distributeurs
    if(globalDataFilterParams.categorie_DISTRIBUTEUR_param && globalDataFilterParams.categorie_DISTRIBUTEUR_param.length > 0) {
      bodyDatas.data.categorie_DISTRIBUTEUR_param = {
        operator: "IN",
        datas: globalDataFilterParams.categorie_DISTRIBUTEUR_param
      }
    }

    if(globalDataFilterParams.categorie_PDV_param && globalDataFilterParams.categorie_PDV_param.length > 0) {
      bodyDatas.data.categorie_PDV_param = {
        operator: "IN",
        datas: globalDataFilterParams.categorie_PDV_param
      }
    }
    if (globalDataFilterParams.pdvs.length !== 0) {
      bodyDatas.data = {
        ...bodyDatas.data,
        msisdn_param: {
          operator: "IN",
          datas: globalDataFilterParams.pdvs
        }
      }
    }
    return this.restClient.post('dashbord/totalTerritoire', bodyDatas)
  }

  getLocalityStock(startDate: string = '', endDate: string = '', hour: { start: string, end: string }, isLastDate: boolean = false, isLastMonth: boolean = false, globalDataFilterParams: { regions: Array<string> | 'all', territoires: Array<string> | 'all', distributeurs: Array<string> | 'all', pdvs: string[] | 'all', categorie_DISTRIBUTEUR_param?: string[], categorie_PDV_param?: string[] }) {
    const bodyDatas = { ...this.nombreRuptureBody }
    bodyDatas.user = this.user.userMatricule
    bodyDatas.isLastDate = isLastDate
    if (!isLastDate) {
      bodyDatas.data.dateDebut = startDate
      bodyDatas.data.dateFin = endDate
      bodyDatas.data.heureParam = hour
    }
    bodyDatas.data.isActif30j = isLastMonth
    bodyDatas.data.regions = globalDataFilterParams.regions
    bodyDatas.data.territoires = globalDataFilterParams.territoires.length === 0 ? [] : globalDataFilterParams.territoires
    bodyDatas.data.distributeurs = globalDataFilterParams.distributeurs.length === 0 ? [] : globalDataFilterParams.distributeurs
    if(globalDataFilterParams.categorie_DISTRIBUTEUR_param && globalDataFilterParams.categorie_DISTRIBUTEUR_param.length > 0) {
      bodyDatas.data.categorie_DISTRIBUTEUR_param = {
        operator: "IN",
        datas: globalDataFilterParams.categorie_DISTRIBUTEUR_param
      }
    }

    if(globalDataFilterParams.categorie_PDV_param && globalDataFilterParams.categorie_PDV_param.length > 0) {
      bodyDatas.data.categorie_PDV_param = {
        operator: "IN",
        datas: globalDataFilterParams.categorie_PDV_param
      }
    }
    return this.restClient.post('dashbord/totalLocalite', bodyDatas)
  }

  getDistributeurStock(startDate: string = '', endDate: string = '', hour: { start: string, end: string }, isLastDate: boolean = false, isLastMonth: boolean = false, globalDataFilterParams: { regions: Array<string> | 'all', territoires: Array<string> | 'all', distributeurs: Array<string> | 'all', pdvs: string[] | 'all', categorie_DISTRIBUTEUR_param?: string[], categorie_PDV_param?: string[] }) {
    const bodyDatas = { ...this.nombreRuptureBody }
    bodyDatas.user = this.user.userMatricule
    bodyDatas.isLastDate = isLastDate
    if (!isLastDate) {
      bodyDatas.data.dateDebut = startDate
      bodyDatas.data.dateFin = endDate
      bodyDatas.data.heureParam = hour
    }
    bodyDatas.data.isActif30j = isLastMonth
    bodyDatas.data.regions = globalDataFilterParams.regions
    bodyDatas.data.territoires = globalDataFilterParams.territoires
    bodyDatas.data.distributeurs = globalDataFilterParams.distributeurs
    if(globalDataFilterParams.categorie_DISTRIBUTEUR_param && globalDataFilterParams.categorie_DISTRIBUTEUR_param.length > 0) {
      bodyDatas.data.categorie_DISTRIBUTEUR_param = {
        operator: "IN",
        datas: globalDataFilterParams.categorie_DISTRIBUTEUR_param
      }
    }

    if(globalDataFilterParams.categorie_PDV_param && globalDataFilterParams.categorie_PDV_param.length > 0) {
      bodyDatas.data.categorie_PDV_param = {
        operator: "IN",
        datas: globalDataFilterParams.categorie_PDV_param
      }
    }
    if (globalDataFilterParams.pdvs.length !== 0) {
      bodyDatas.data = {
        ...bodyDatas.data,
        msisdn_param: {
          operator: "IN",
          datas: globalDataFilterParams.pdvs
        }
      }
    }
    return this.restClient.post('dashbord/totalDistributeur', bodyDatas)
  }

  getGraphDatas(startDate: string = '', endDate: string = '', hour: { start: string, end: string }, isLastDate: boolean = false, isLastMonth: boolean = false, globalDataFilterParams?: { regions: Array<string> | 'all', territoires: Array<string> | 'all', distributeurs: Array<string> | 'all', pdvs: string[] | 'all', categorie_DISTRIBUTEUR_param?: string[], categorie_PDV_param?: string[] }) {
    const bodyDatas = { ...this.nombreRuptureBody }
    bodyDatas.user = this.user.userMatricule
    bodyDatas.isLastDate = isLastDate
    if (!isLastDate) {
      bodyDatas.data.dateDebut = startDate
      bodyDatas.data.dateFin = endDate
      bodyDatas.data.heureParam = hour
    }
    bodyDatas.data.isActif30j = isLastMonth
    if(globalDataFilterParams?.categorie_DISTRIBUTEUR_param && globalDataFilterParams?.categorie_DISTRIBUTEUR_param.length > 0) {
      bodyDatas.data.categorie_DISTRIBUTEUR_param = {
        operator: "IN",
        datas: globalDataFilterParams.categorie_DISTRIBUTEUR_param
      }
    }

    if(globalDataFilterParams?.categorie_PDV_param && globalDataFilterParams?.categorie_PDV_param.length > 0) {
      bodyDatas.data.categorie_PDV_param = {
        operator: "IN",
        datas: globalDataFilterParams.categorie_PDV_param
      }
    }
    if (globalDataFilterParams && globalDataFilterParams?.pdvs.length !== 0) {
      bodyDatas.data = {
        ...bodyDatas.data,
        msisdn_param: {
          operator: "IN",
          datas: globalDataFilterParams.pdvs
        }
      }
    }
    return this.restClient.post('dashbord/grapheRegionDetailsNbreRupture', bodyDatas)
  }

  getInfoSegment(startDate: string = '', endDate: string = '', hour: { start: string, end: string }, isLastDate: boolean = false, isLastMonth: boolean = false, globalDataFilterParams: { regions: Array<string> | 'all', territoires: Array<string> | 'all', distributeurs: Array<string> | 'all', pdvs: string[] | 'all', categorie_DISTRIBUTEUR_param?: string[], categorie_PDV_param?: string[] }): Observable<ISegmentResponseData> {
    const bodyDatas = { ...this.nombreRuptureBody }
    bodyDatas.user = this.user.userMatricule
    bodyDatas.data.regions = globalDataFilterParams.regions
    bodyDatas.data.territoires = globalDataFilterParams.territoires
    bodyDatas.data.distributeurs = globalDataFilterParams.distributeurs
    if(globalDataFilterParams.categorie_DISTRIBUTEUR_param && globalDataFilterParams.categorie_DISTRIBUTEUR_param.length > 0) {
      bodyDatas.data.categorie_DISTRIBUTEUR_param = {
        operator: "IN",
        datas: globalDataFilterParams.categorie_DISTRIBUTEUR_param
      }
    }

    if(globalDataFilterParams.categorie_PDV_param && globalDataFilterParams.categorie_PDV_param.length > 0) {
      bodyDatas.data.categorie_PDV_param = {
        operator: "IN",
        datas: globalDataFilterParams.categorie_PDV_param
      }
    }
    bodyDatas.isLastDate = isLastDate
    if (!isLastDate) {
      bodyDatas.data.dateDebut = startDate
      bodyDatas.data.dateFin = endDate
      bodyDatas.data.heureParam = hour
    }

    if (globalDataFilterParams.pdvs.length !== 0) {
      bodyDatas.data = {
        ...bodyDatas.data,
        msisdn_param: {
          operator: "IN",
          datas: globalDataFilterParams.pdvs
        }
      }
    }
    bodyDatas.data.isActif30j = isLastMonth
    return this.restClient.post('omerPdvHist/getInfosSegment', bodyDatas)
  }
}
